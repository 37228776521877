export const fields = [
    { key: 'date', label: "Tanggal ", _style: 'min-width:100px' },
    { key: 'created_at', label: "Jam ", _style: 'min-width:50px' },
    { key: 'name', label: "Nama", _style: 'min-width:100px' },
    { key: 'nip', label: "NIP", _style: 'min-width:100px' },
    { key: 'group', label: "Golongan", _style: 'min-width:100px;' },
    { key: 'position', label: "Jabatan", _style: 'min-width:100px;' },
    { key: 'report', label: "Laporan", _style: 'max-width:100px' },
    { key: 'updated_at', label: "Diubah", _style: 'min-width:100px' },

    { key: "action", label: 'Aksi', _style: 'min-width:75px' }
]
